@import '../../../styles/variables.module.scss';

.container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	background-color: white;
}

.video {
	height: 100%;
	object-fit: contain;
	@media screen and (min-width: $breakpoint-md) {
		object-fit: cover;
	}
}
