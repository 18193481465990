@import '../../styles/variables.module.scss';

.name {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 55%;

	@media screen and (min-width: $breakpoint-md) {
		width: 60%;
	}
}

.nameCompact {
	width: 35%;

	@media screen and (min-width: $breakpoint-md) {
		width: 42%;
	}
}

.infoIcon {
	margin-left: 1rem;
}

.errorContainer {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.checkIcon {
	position: relative;
	top: 7px;
	margin-right: 0.5rem;
}

.header {
	width: 100%;
	text-align: center;
	margin-bottom: 0.5rem;
}

.notEligibleText {
	color: var(--color-grey);
}

.infoIcon {
	position: relative;
	top: 4px;
	display: inline-block;
	width: 22px;
	height: 22px;
	border: 2px solid grey;
	border-radius: 21px;
	cursor: pointer;

	@media screen and (min-width: $breakpoint-md) {
		top: 1px;
	}
}

.infoIcon:hover {
	background-color: grey;
}

.infoIcon:hover .infoIconText {
	color: white;
}

.infoIconText {
	position: relative;
	top: -5px;
	left: 7px;
	font-family: 'PP Mori';
	font-size: 15px;
	color: grey;
}

.txLinkColumn {
	display: flex;
	justify-content: flex-end;
	width: 50px;
}

.txLink {
	position: relative;
	top: 0;
	cursor: pointer;
	width: 12px;

	@media screen and (min-width: $breakpoint-md) {
		top: 5px;
		width: 16px;
	}
}

.link {
	text-decoration: underline;
	color: var(--color-black);
	font-weight: 800;
	cursor: pointer;
}

.popoverContent {
	max-width: 83vw;
	border: 2px solid var(--color-bd-purple);
	border-top: 10px solid var(--color-bd-purple);
	padding: 1.5rem;
	background-color: white;
	border-radius: 0.5rem;
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
	outline: none;
	z-index: 100;

	@media screen and (min-width: $breakpoint-md) {
		max-width: 575px;
	}
}

.previouslyClaimedText {
	font-size: clamp(0.525rem, calc(5vw - 0.5rem), 0.625rem);
	font-weight: 500;
	text-align: left;
	padding-bottom: 0;

	@media screen and (min-width: $breakpoint-md) {
		font-size: clamp(0.6rem, calc(5vw - 0.5rem), 0.875rem);
	}
}

// Case the text is above tokens
// .checkEligibility {
// 	float: left;

// 	@media screen and (min-width: 384px) {
// 		float: right;
// 	}
// }

.checkEligibility {
	float: right;
	font-size: clamp(0.525rem, calc(5vw - 0.5rem), 0.625rem);
	font-weight: 500;
	text-align: left;
	padding-bottom: 0;

	@media screen and (min-width: $breakpoint-md) {
		font-size: clamp(0.6rem, calc(5vw - 0.5rem), 0.875rem);
	}
}

.minMarginLeft {
	padding-left: 0.5rem;

	@media screen and (min-width: $breakpoint-md) {
		padding-left: 2rem;
	}
}

.arrow {
	fill: #fff;
}

.borderBottom {
	border-bottom: 1px solid #c8c8c8;
}

.footerPadding {
	padding: 0.25rem 0;
}

.quantityColumn {
	width: 20%;
}

.tokensColumn {
	width: 25%;
}

.table {
	table-layout: fixed;
	width: 100%;
}

.tokensTotalText {
	float: right;
}
