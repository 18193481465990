@import '../../styles/variables.module.scss';

.innerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;

	@media screen and (min-width: $breakpoint-md) {
		flex-direction: row;
		align-items: center;
	}
}

.textContainer {
	margin: 10px 0;
}
