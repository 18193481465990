@import '../../styles/variables.module.scss';

.wrapper {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	backdrop-filter: blur(8.5px);
	z-index: 9999;
}

.card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 280px;
	height: 276px;
	padding: 5rem 2rem;
	border: 2px solid var(--color-purple);
	border-top-width: 17px;
	border-radius: 12px;
	background-color: var(--color-white);

	@media screen and (min-width: $breakpoint-md) {
		width: 480px;
		height: 353px;
		border-top-width: 14px;
	}
}

.loader {
	width: 203px;
	height: 58px;

	@media screen and (min-width: $breakpoint-md) {
		width: 219px;
		height: 62px;
	}
}

.text {
	color: var(--color-grey);
}
