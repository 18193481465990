@import '../../../styles/variables.module.scss';

.container {
	display: flex;
	height: auto;
	min-height: none;
	background-color: #d1e7ff;
	z-index: 1;
	flex-direction: column-reverse;

	@media screen and (min-width: $breakpoint-md) {
		height: 100vh;
		min-height: 600px;
		flex-direction: column;
	}
}

.sectionRight {
	flex-direction: column;
	justify-content: center;
	padding: 5rem 1.25rem 1.25rem 1.25rem;

	@media screen and (min-width: $breakpoint-md) {
		padding: 5rem;
	}
}

.text:not(:last-child)::after {
	display: inline-block;
	width: 100%;
	height: 1px;
	margin: 1rem 0;
	border-top: 1px solid #052549;
	content: '';
}
