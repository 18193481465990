@import '../../styles/variables.module.scss';

.faq {
	display: flex;
	font-family: 'PP Mori';
	font-weight: 600;
	color: #052549;
	align-items: center;
	justify-content: center;
	font-size: 5rem;
	background-color: #d1e7ff;
	padding-top: 40px;
	@media screen and (max-width: 767px) {
		font-size: 3rem;
	}
}
.page {
	display: flex;
	font-family: 'PP Mori';
	p {
		font-size: 18px;
	}
	@media screen and (max-width: 767px) {
		padding: 0 40px 40px 40px;
	}
	@media screen and (min-width: 768px) {
		padding: 0 60px 60px 60px;
	}
}
.pageOne {
	color: #052549;
	background-color: #d1e7ff;
	p {
		color: #052549;
	}
}
.pageTwo {
	color: #382800;
	background-color: #ffe6aa;
	p {
		color: #382800;
	}
}
.pageThree {
	color: #1b1b1b;
	background-color: #ebebff;
	flex-direction: column;
	p {
		color: #1b1b1b;
	}
}
.pageFour {
	color: #1f0061;
	background-color: #d4d6ff;
	p {
		color: #1f0061;
	}
}
.pageFive {
	color: #1f0061;
	background-color: #d4d6ff;
	flex-direction: column;
	p {
		color: #1f0061;
	}
}
.emojiContainer {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 767px) {
		display: none;
	}
	@media screen and (min-width: 768px) {
		width: 25vw;
		img {
			width: 23vw;
		}
	}
}
.pictureTextContainer {
	display: flex;
	@media screen and (max-width: 767px) {
		flex-direction: column;
	}
}
.pictureContainer {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	padding: 30px;
	@media screen and (max-width: 767px) {
		img {
			width: 100%;
		}
	}
	@media screen and (min-width: 768px) {
		width: 50vw;
		img {
			width: 100%;
		}
	}
}
.textContainer {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.pageTitle {
	display: flex;
	font-size: 28px;
	font-weight: 600;
	margin-top: 64px;
}
.pageDesc {
	font-size: 16px;
	margin-top: 12px;
	letter-spacing: 0.01em;
	.discord {
		@media screen and (max-width: 767px) {
			width: 100%;
		}
		@media screen and (min-width: 768px) {
			width: 400px;
		}
	}
}
.pagePadding {
	padding-left: 30px;
	@media screen and (max-width: 767px) {
		padding-left: 0px;
	}
}
.notice {
	font-style: italic;
	font-weight: 600;
}

.introLink {
	text-decoration: underline;
	font-size: 18px;
	cursor: pointer;
	color: #383838;
}

.addrLink {
	text-decoration: underline;
	font-size: 20px;
	cursor: pointer;
	color: #052549;
}

.introLink:hover {
	color: #737373;
}

.disclaimer {
	color: #737373;
}

.disclaimerMessage {
	margin-top: 1rem;
	font-size: 1rem;
	border-left: 3px solid var(--color-bg-purple);
	padding-left: 0.5rem;
}
