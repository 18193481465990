@import '../../styles/variables.module.scss';

.wrapper {
	position: relative;
	display: flex;
	min-height: 100vh;
	background-color: #ffe6aa;
	flex-direction: column;
	overflow: hidden;
}

.videoContainer {
	position: relative;
	height: calc(100vh - var(--top-bar-height-mobile));
	min-height: calc(100vh - var(--top-bar-height-mobile));

	width: 100%;
	flex: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	@media screen and (min-width: $breakpoint-md) {
		height: calc(100vh - var(--top-bar-height));
		min-height: calc(100vh - var(--top-bar-height));
		background-position: bottom;
		background-size: cover;
	}
}

.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: $breakpoint-md) {
		background-position: bottom;
		background-size: cover;
	}
}

.mascot {
	position: relative;
	width: calc(100% - 1.25rem);

	@media screen and (min-width: $breakpoint-md) {
		position: absolute;
		max-width: 900px;
	}

	@media screen and (min-width: $breakpoint-md) and (max-width: 1400px) {
		left: 45%;
	}

	@media screen and (max-width: 2100px) and (min-width: 1400px) {
		left: 50%;
	}

	@media screen and (max-width: 3000px) and (min-width: 2100px) {
		left: 60%;
	}
}

.bgYellow {
	background-color: var(--color-bg-yellow);
}

.bgPurple {
	background-color: var(--color-bg-purple);
}

.bgGreen {
	background-color: var(--color-bg-green);
}

.globeYellowSmallContainer {
	position: absolute;
	top: 10%;
	left: 5%;
}

.globeYellowSmall {
	width: 300px;
}

.globeYellowBigContainer {
	position: absolute;
	top: 45%;
	left: 40%;
}

.globeYellowBig {
	width: 1200px;
	filter: blur(3px);
}

.globePurpleSmallContainer {
	position: absolute;
	top: 10%;
	left: 5%;
}

.globePurpleSmall {
	width: 300px;
}

.globePurpleBigContainer {
	position: absolute;
	bottom: -70%;
	left: 25%;
}

.globePurpleBig {
	width: 1300px;
}

.globeGreenSmallContainer {
	position: absolute;
	top: 7%;
	left: 3%;
}

.globeGreenSmall {
	width: 300px;
}

.globeGreenBigContainer {
	position: absolute;
	bottom: -70%;
	left: 20%;
}

.globeGreenBig {
	width: 1700px;
}

.globeBlueSmallContainer {
	position: absolute;
	top: -10%;
	right: 15%;
}

.globeBlueSmall {
	width: 350px;
	filter: blur(5px);
}

.globeBlueMediumContainer {
	position: absolute;
	bottom: 0;
	left: -25%;
}

.globeBlueMedium {
	width: 800px;
}

.globeBlueBigContainer {
	position: absolute;
	bottom: -80%;
	right: -800px;
}

.globeBlueBig {
	width: 1500px;
}
