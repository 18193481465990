@import '../../styles/variables.module.scss';

.button {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 56px;
	padding: 0px 20px;
	border: 0;
	background: var(--color-button-basic);
	color: var(--color-button-basic-text);
	border-radius: 9px;
	cursor: pointer;
}

.buttonDisabled {
	opacity: 0.5;
}

.buttonHover:hover {
	background: var(--color-button-basic-hover);
}

.white {
	background: var(--color-button-white);
	color: var(--color-button-white-text);

	&:not(:disabled):hover {
		background: var(--color-button-white-hover);
	}
}

.border {
	border: 1px solid var(--color-button-basic);
}
