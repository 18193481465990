@import '../../../styles/variables.module.scss';

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.header {
	width: 100%;
	text-align: center;
	margin-top: 1rem;
}

.socialContainer {
	display: flex;
	gap: 1.25rem;
	margin-top: 1rem;

	@media screen and (min-width: $breakpoint-md) {
		width: auto;
		margin-top: 0;
	}
}

.footer {
	display: inline-block;
	width: auto;

	@media screen and (min-width: $breakpoint-md) {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}

.link {
	align-self: center;
	font-family: 'PP Mori';
	font-style: normal;
	font-weight: 600;
	font-size: 21px;
	line-height: 27px;
	text-decoration-line: underline;
	color: #1f0061;
}

.socialButton {
	width: 40px;
	height: 40px;
	color: var(--color-black);
}

.mascot {
	width: 100%;
}
