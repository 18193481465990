@import '../../../styles/variables.module.scss';

.container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 8rem;
	width: 100%;
	height: 100vh;
	min-height: none;
	background-color: var(--color-purple);
	background-image: url('../../../images/claim_tokens_mobile_bg.png');
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	z-index: 1;

	@media screen and (min-width: $breakpoint-md) {
		min-height: 1000px;
		background-image: url('../../../images/claim_tokens_bg.png');
	}
}
