@import '../../styles/variables.module.scss';

.container:not(:last-child)::after {
	display: inline-block;
	width: 100%;
	height: 1px;
	margin: 1.5rem 0;
	border-top: 1px solid #baffd7;
	content: '';

	@media screen and (min-width: $breakpoint-md) {
		margin: 1rem 0;
	}
}

.blue:not(:last-child)::after {
	border-top: 1px solid #a0cdff;
}
