@import '../../styles/variables.module.scss';

.wrapper {
	height: 100%;
	overflow: auto;
}

.bgPurple {
	background-color: var(--color-bg-purple);
}

.container {
	flex-direction: column;
	width: 100%;
	height: auto;
	min-height: 600px;

	@media screen and (min-width: $breakpoint-md) {
		flex-direction: row;
		height: calc(100vh - 178px);
	}
}

.leftWrapper {
	min-width: min-content;
	z-index: 1;
	justify-content: center;
	background-color: var(--color-white);
}

.leftContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 1.25rem;
	width: 100%;
	height: auto;
	background-color: var(--color-white);
	margin: 3rem 0;
	max-width: 800px;
	min-height: 600px;

	@media screen and (min-width: $breakpoint-md) {
		height: calc(100vh - 178px);
		padding: 5rem;
		margin: 0;
	}
}

.rightContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 178px);
	margin: 3rem 0;
	min-height: 600px;

	@media screen and (min-width: $breakpoint-md) {
		margin: 0;
	}
}

.unclaimedCoins {
	display: block;
	width: auto;
	height: auto;
	max-width: 50%;
	max-height: 50%;
}

.buttonContainer {
	display: flex;
	flex-direction: row;
}

.buttonLeft {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.buttonRight {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.tokenHeader {
	width: 100%;
	border-bottom: 1px solid lightgray;
	padding: 0.5rem 1rem;
}

.tokenContainer {
	width: 100%;
	padding: 0.5rem 0;
	overflow-y: visible;
	border-bottom: 1px solid lightgray;
	min-height: 100px;

	@media screen and (min-width: $breakpoint-md) {
		overflow-y: auto;
	}
}

.tokenLine {
	display: block;
	height: 27px;
	padding: 0.35rem 1rem 0.2rem 1rem;
	cursor: pointer;

	text-decoration: none;
	color: black;

	font-weight: initial;
	font-size: initial;
	line-height: initial;

	&:hover {
		background-color: #eee;
	}
}

.txLink {
	position: relative;
	float: right;
	top: 0;
	width: 12px;
	cursor: pointer;

	@media screen and (min-width: $breakpoint-md) {
		top: -1px;
		width: 16px;
	}
}

.loader {
	display: flex;
	width: 100%;
	min-height: 140px;
	align-items: center;
	justify-content: center;
}

.error {
	margin-top: 1rem;
	color: red;
}

.scrollTopButton {
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 1000;

	width: 50px;
	height: 50px;
	border-radius: 100%;
	border: none;
	color: white;
	background-color: var(--color-bg-purple);
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);

	transition: opacity 0.5s, visibility 0.5s;
	opacity: 0;
	visibility: hidden;

	&.show {
		opacity: 1;
		visibility: visible;
	}

	@media screen and (min-width: $breakpoint-md) {
		display: none;
	}
}

.textContainer {
	padding: 0 3rem;
	text-align: center;
}

.warningText {
	margin-top: 0.5rem;
	font-style: italic;
}
