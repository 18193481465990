@import '../../styles/variables.module.scss';

.button {
	display: none;
	width: 170px;
	height: 170px;
	border: 0;
	background-color: transparent;
	color: var(--color-white);
	border-radius: 170px;
	cursor: pointer;
	z-index: 1001;

	@media screen and (min-width: $breakpoint-md) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.buttonDisabled {
	opacity: 0.5;
}

.buttonHover:hover {
	color: var(--color-white);
	background-color: transparent;
}

.button:hover .svgHoverFill {
	fill: #382800;
}

.button:hover .svgHoverStroke {
	color: #382800;
}
