@import '../../styles/variables.module.scss';

.wrapper {
	display: flex;
	min-height: 100vh;
	background-color: #fff3d4;
	background-repeat: no-repeat;
	background-position: right;
	background-size: cover;
	flex-direction: column;
}

.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	background-image: url('../../images/hearts_mobile_bg.png');
	background-repeat: no-repeat;
	background-position: 50% -44px;
	background-size: auto;

	@media screen and (min-width: $breakpoint-md) {
		background-image: url('../../images/rainbow_bg.png');
		background-position: 50% 32vh;
		background-size: contain;
	}
}

.card {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	width: auto;
	margin: 9rem 1.5rem;

	border: 2px solid #d5d6ff;
	border-top-width: 14px;
	background-color: white;
	box-shadow: 49px 55px 30px rgba(0, 0, 0, 0.01),
		28px 31px 25px rgba(0, 0, 0, 0.03), 12px 14px 19px rgba(0, 0, 0, 0.04),
		3px 3px 10px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
	border-radius: 12px;

	z-index: 1;

	@media screen and (min-width: $breakpoint-md) {
		width: 650px;
		margin: 6rem 1.5rem;
	}
}

.header {
	margin-top: 2rem;

	@media screen and (min-width: $breakpoint-md) {
		margin-top: 1rem;
	}
}

.heart {
	position: relative;
	margin-top: -65px;
	width: 52px;

	@media screen and (min-width: $breakpoint-md) {
		width: 159px;
		margin-top: -148px;
	}
}

.alignCenter {
	width: 100%;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
}

.claimAnalysisContainer {
	width: 100%;

	@media screen and (min-width: $breakpoint-md) {
		width: 475px;
	}
}

.walletAddress {
	font-size: clamp(0.75rem, calc(6vw - 1rem), 1.25rem);
}

.walletAddressText {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 400;
	font-size: 1.625rem;
	line-height: 1.5;
	text-align: center;
	color: var(--color-grey);
}

.heartLeftContainer {
	position: absolute;
	top: 30px;
	left: calc(50% - 660px);
}

.heartLeft {
	width: 480px;
}

.heartRightContainer {
	position: absolute;
	top: 120px;
	right: calc(50% - 630px);
}

.heartRight {
	width: 280px;
}

.heartLeftBlurContainer {
	position: absolute;
	top: 50%;
	left: calc(50% - 750px);
}

.heartLeftBlur {
	width: 220px;
}

.heartRightBlurContainer {
	position: absolute;
	top: 55%;
	right: calc(40% - 550px);
}

.heartRightBlur {
	width: 180px;
}

.socialsContainer {
	align-items: flex-end;
	display: flex;
	margin-top: 2rem;
	gap: 0.5rem;
}
