@import '../../styles/variables.module.scss';

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 20px;
	padding-bottom: 0;
	text-align: center;
}

.icon {
	width: 200px;
	max-width: 100%;
	height: auto;
	padding: 1rem;

	background-color: var(--color-purple);
	border-radius: 50%;
}

.buttonContainer {
	margin-top: 2.75rem;

	display: flex;
	flex-wrap: wrap;
	gap: 1rem;

	@media screen and (min-width: $breakpoint-md) {
		gap: 2rem;
	}
}

.buttonSuccess {
	padding: 1rem 3rem;

	font-family: var(--font-primary);
	font-size: 1rem;
	font-weight: 600;
}

.nftLink {
	margin-top: 1rem;

	// Pushing it in the parent's padding to avoid
	// making the box larger
	transform: translateY(100%);
}
