@import '../../styles/variables.module.scss';

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 100%;
	overflow: hidden;
}
