@import '../../../styles/variables.module.scss';

.container {
	height: auto;
	flex-direction: column-reverse;

	@media screen and (min-width: $breakpoint-md) {
		height: 100vh;
	}
}

.sectionLeft {
	flex-direction: column;
	justify-content: center;
	background-color: #92ecb8;
	padding: 3.75rem 0;

	@media screen and (min-width: $breakpoint-md) {
		padding: 0;
	}
}

.distribution {
	padding: 1.5rem;

	@media screen and (min-width: $breakpoint-md) {
		padding: 4rem;
	}
}

.sectionRight {
	flex-direction: column;
	justify-content: center;
	background-color: white;
}

.sectionRightContent {
	padding: 4.5rem 1.25rem;

	@media screen and (min-width: $breakpoint-md) {
		padding: 5rem;
	}
}

.paperplane {
	position: relative;
	margin-top: -1.5rem;
	margin-left: 1rem;
}
