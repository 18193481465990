@use '../../styles/variables.module.scss' as v;

.wrapper {
	position: relative;
	height: 100%;

	display: flex;
	flex-direction: column;

	overflow: auto;
	// scroll-behavior: smooth;
}

.bgElements {
	position: absolute;
	width: 100%;
	height: 100%;

	overflow: hidden;
}

.bgPurple {
	background-color: var(--color-bg-purple);
}

.container {
	flex: 1;
	width: inherit;
	min-height: calc(100vh - 178px);

	display: flex;
	align-items: center;
}

.card {
	position: relative;
	display: flex;
	transform: none;
	margin: 20px;
	flex-direction: column;
	z-index: 100;

	@media screen and (min-width: v.$breakpoint-md) {
		width: 613px;
		margin-bottom: 100px;
		flex-direction: row;
	}

	@media screen and (min-width: v.$breakpoint-md) and (max-width: 1500px) {
		left: 1%;
	}

	@media screen and (max-width: 2100px) and (min-width: 1500px) {
		left: 10%;
	}

	@media screen and (max-width: 3000px) and (min-width: 2100px) {
		left: 20%;
	}
}

.box {
	padding: 40px;
	min-width: 100%;
	z-index: 1;

	@media screen and (min-width: v.$breakpoint-md) {
		padding: 40px;
	}
}

.visibleBox {
	min-width: 100%;
	border-style: solid;
	border-width: 2px;
	border-top-width: 17px;
	background-color: white;
	border-radius: 12px;
	border-color: var(--color-bd-purple);
	box-shadow: 49px 55px 30px rgba(0, 0, 0, 0.01),
		28px 31px 25px rgba(0, 0, 0, 0.03), 12px 14px 19px rgba(0, 0, 0, 0.04),
		3px 3px 10px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
	z-index: 1;

	@media screen and (min-width: v.$breakpoint-md) {
		border-top-width: 14px;
	}
}

.hiddenBox {
	transform: translateY(-100%);
	background-color: #d1e7ff;
	transition: 0.35s;
	border-radius: 20px;
	overflow: hidden;
	opacity: 0;
	z-index: 0;

	@media screen and (min-width: v.$breakpoint-md) {
		transform: translateX(-100%);
	}
}

.hiddenBoxVisible {
	transform: translateY(-16px);
	overflow: hidden;
	border-radius: 12px;
	opacity: 1;
	z-index: 0;

	@media screen and (min-width: v.$breakpoint-md) {
		transform: translateX(-12px);
		z-index: 2;
	}

	@media screen and (min-width: 1200px) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		z-index: 0;
	}
}

.discordMessage {
	margin-top: 1rem;
	font-size: 1rem;
	border-left: 2px solid var(--color-bg-purple);
	padding-left: 0.5rem;
	font-size: clamp(0.525rem, calc(5vw - 0.5rem), 0.625rem);

	@media screen and (min-width: v.$breakpoint-md) {
		font-size: clamp(0.875rem, calc(5vw - 0.5rem), 1rem);
	}
}

.discordLink {
	text-decoration: underline;
	color: var(--color-black);
	font-weight: 800;
	cursor: pointer;
}

.claimContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;

	@media screen and (min-width: v.$breakpoint-md) {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.inputLabel {
	margin-bottom: 0.75rem;
}

.walletInputContainer {
	display: flex;
	flex-direction: row;
	flex: 1;
}

.walletLedger {
	padding: 0.675rem;
	width: 56px;
	height: 56px;
	background-color: var(--color-button-basic);
	border-top-left-radius: 9px;
	border-bottom-left-radius: 9px;
}

.walletInput {
	flex: 1;
	height: 56px;
	padding: 0 0.75rem;
	font-style: normal;
	font-weight: 400;
	font-size: 0.85rem;
	line-height: 1.5;
	border: 1px solid var(--color-button-basic);
	border-radius: 9px;
}

.walletInputError {
	border-color: red;
}

.radioFieldset {
	border: none;
	margin: 0;
	padding: 0;
	margin-top: 3rem;

	display: flex;
	flex-direction: column;
	gap: 1rem;

	line-height: 1.3;

	& > legend {
		margin-bottom: 1.2rem;
	}

	& > div {
		display: flex;
		align-items: center;
		column-gap: 1.5rem;

		input {
			width: 1.125rem;
			height: 1.125rem;
			cursor: pointer;
		}
		label {
			color: var(--color-grey);
			cursor: pointer;
		}
	}
}

.checkButton {
	// Need to override the default width set with inline styles
	// Safe to use since this is a very scoped class
	width: auto !important;
}

.closeHeader {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.closeIcon {
	width: 38px;
	height: 38px;
	background-size: 38px;
	background-image: url('../../icons/close_info.png');
	cursor: pointer;

	&:hover {
		background-image: url('../../icons/close_info_hover.png');
	}
}

.error {
	min-height: 2rem;
	width: 100%;
	color: red;

	// Needs this padding to avoid the container resizing
	// when results card opens and is smaller than default
	padding-bottom: 43px;

	&:empty {
		visibility: hidden;
	}
}

.link {
	display: block;
	color: var(--color-black);
}
