@import '../../../styles/variables.module.scss';

.container {
	height: auto;
	min-height: auto;
	padding-top: 40px;
	z-index: 0;

	@media screen and (min-width: $breakpoint-md) {
		height: 100vh;
		min-height: 600px;
		padding-top: 0;
	}
}

.leftWrapper {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: min-content;
	margin-left: 2rem;
	z-index: 1;
}

.leftContainer {
	width: 100%;
	padding: 1.25rem;
	margin-top: 2rem;
	max-width: 30rem;

	@media screen and (min-width: $breakpoint-md) {
		width: 34rem;
		padding: 0;
		margin-top: 0;
		max-width: 60rem;
	}
}

.rightContainer {
	justify-content: flex-end;
}

.coin {
	position: relative;
	margin-top: -2.5rem;
	margin-left: 1rem;
}

.mascot {
	width: calc(100vw - 1.25rem);
	height: auto;
	clip-path: inset(0 0 25% 0);

	@media screen and (min-width: $breakpoint-md) {
		position: absolute;
		top: 2%;
		width: 773px;
		right: 0;
		margin-left: 0;
		clip-path: none;
	}

	@media screen and (min-width: $breakpoint-md) and (max-width: 1500px) {
		width: 600px;
	}

	@media screen and (min-width: $breakpoint-md) and (max-width: 1100px) {
		top: 4%;
		width: 500px;
	}

	@media screen and (min-width: $breakpoint-md) and (max-width: 1000px) {
		top: 7%;
		width: 400px;
	}
}

.buttonContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.eligibilityButton {
	width: 218px;
	margin-top: 0;

	border-radius: 9px;
	border: 1px solid var(--color-button-basic);

	font-family: var(--font-primary);
	font-size: 1rem;
	font-weight: 600;

	&:hover {
		color: white;
	}

	@media screen and (min-width: $breakpoint-md) {
		margin-top: 2rem;
	}
}
