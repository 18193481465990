@import '../../styles/variables.module.scss';

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.title {
	font-weight: 600;
	font-size: 0.5rem;
	line-height: 1rem;

	@media screen and (min-width: $breakpoint-md) {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

.subtitle {
	font-size: 0.5rem;

	@media screen and (min-width: $breakpoint-md) {
		font-size: 1rem;
	}
}
