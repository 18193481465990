@import '../../styles/variables.module.scss';

.wrapper {
	position: relative;
	display: flex;
	min-height: 100vh;
	background-color: var(--color-bd-purple);
	flex-direction: column;
	overflow: hidden;
}

.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 2rem 1rem;

	@media screen and (min-width: $breakpoint-md) {
		padding: 5rem;
		background-position: bottom;
		background-size: cover;
	}

	h3 {
		margin-top: 2rem;
		margin-bottom: 0.5em;

		&:hover .faqFragment {
			visibility: visible;
		}
	}

	h6 {
		margin: 0.25em 0;
		color: var(--color-grey);
	}
}
.faqLink {
	text-decoration: underline;
	font-size: inherit;
	line-height: inherit;
	cursor: pointer;
	color: #383838;
}

.faqLink:hover {
	color: #737373;
}

.faqGif {
	width: 400px;
	max-width: 100%;
	margin-top: 1rem;
}

.faqFragment {
	all: unset;
	margin-left: -20px;
	padding-right: 5px;
	visibility: hidden;

	cursor: pointer;
}
