@import '../../styles/variables.module.scss';

.color {
	color: var(--color-black);

	@media screen and (min-width: $breakpoint-md) {
		color: var(--color-white);
	}
}

.subtitle {
	margin-top: 1.5rem;
}

.headerContainer {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	background-color: white;
	width: 100%;
	height: 135px;
	justify-content: center;
	top: 0;
	z-index: 1;
	user-select: none;

	@media screen and (min-width: $breakpoint-md) {
		background-color: transparent;
		width: auto;
		height: auto;
		justify-content: center;
		top: var(--top-bar-height-mobile);
	}
}

.bottomContainer {
	position: absolute;
	bottom: 10vh;
	display: flex;
	gap: 1.625rem;
	padding: 1.25rem;
	flex-direction: column;
	width: 100%;

	transition: opacity 0.5s ease-in-out;

	@media screen and (min-width: $breakpoint-md) {
		padding: 0;
		flex-direction: row;
		width: auto;
	}
}

.pauseVideoButton {
	position: absolute;
	right: auto;

	@media screen and (min-width: $breakpoint-md) {
		right: 5.875rem;
	}
}

.button {
	width: 100%;

	@media screen and (min-width: $breakpoint-md) {
		width: 198px;
	}
}
