@import '../../../styles/variables.module.scss';

.container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100vh;
	min-height: 600px;
	max-height: 700px;
	background-color: white;
	background-image: url('../../../images/when_to_claim_mobile_bg.png');
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;

	@media screen and (min-width: $breakpoint-md) {
		height: 100vh;
		max-height: none;
		background-image: url('../../../images/when_to_claim_bg.png');
	}
}

.bell {
	width: 20%;
	height: auto;

	@media screen and (max-width: $breakpoint-sm) {
		width: 50%;
	}

	@media screen and (min-width: $breakpoint-md) {
		width: 319px;
		height: 337px;
	}
}
