@import '../../styles/variables.module.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 1.1rem;
	padding: 0px 0.5rem;
	border: 0;
	background: var(--color-white);
	border-radius: 9px;
	font-weight: 600;
	font-size: 0.6rem;
	line-height: 1;
	border: 1px solid rgba(127, 133, 150, 0.3);
	border-radius: 0.2rem;

	@media screen and (min-width: $breakpoint-md) {
		height: 2.2rem;
		padding: 0px 1rem;
		font-size: 0.875rem;
		border: 2px solid rgba(127, 133, 150, 0.3);
		border-radius: 0.5rem;
	}
}

.wrapper:hover {
	background: var(--color-button-basic-hover);
}
