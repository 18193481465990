@import '../../styles/variables.module.scss';

.container {
	height: 100%;
	width: inherit;
}

.leftContainer {
	justify-content: center;
	align-items: center;
	align-content: center;
}

.backdropBlur {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	backdrop-filter: blur(8.5px);
	z-index: 10;

	@media screen and (min-width: $breakpoint-md) {
		display: none;
	}
}

.card {
	position: relative;
	display: flex;
	transform: none;
	margin: 20px;
	flex-direction: column;
	z-index: 100;

	@media screen and (min-width: $breakpoint-md) {
		width: 613px;
		margin-bottom: 100px;
		flex-direction: row;
	}

	@media screen and (min-width: $breakpoint-md) and (max-width: 1500px) {
		left: 1%;
	}

	@media screen and (max-width: 2100px) and (min-width: 1500px) {
		left: 10%;
	}

	@media screen and (max-width: 3000px) and (min-width: 2100px) {
		left: 20%;
	}
}

.box {
	padding: 40px;
	min-height: 250px;
	min-width: 100%;
	z-index: 1;
	height: auto;

	@media screen and (min-width: $breakpoint-md) {
		padding: 40px;
	}
}

.bdYellow {
	border-color: var(--color-bd-yellow);
}

.bdPurple {
	border-color: var(--color-bd-purple);
}

.bdGreen {
	border-color: var(--color-bd-green);
}

.visibleBox {
	min-width: 100%;
	border-style: solid;
	border-width: 2px;
	border-top-width: 17px;
	background-color: white;
	border-radius: 12px;
	box-shadow: 49px 55px 30px rgba(0, 0, 0, 0.01),
		28px 31px 25px rgba(0, 0, 0, 0.03), 12px 14px 19px rgba(0, 0, 0, 0.04),
		3px 3px 10px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
	z-index: 1;

	@media screen and (min-width: $breakpoint-md) {
		border-top-width: 14px;
	}
}

.hiddenBox {
	position: absolute;
	top: 0;
	background-color: #d1e7ff;
	transition: opacity 0.35s, transform 0.35s;
	border-radius: 20px;
	overflow: hidden;
	opacity: 0;
	z-index: 0;

	@media screen and (min-width: $breakpoint-md) {
		position: relative;
		top: 0;
		transform: translateX(-100%);
	}
}

.hiddenBoxVisible {
	position: absolute;
	top: 0;
	height: 100%;
	overflow: hidden;
	border-radius: 12px;
	opacity: 1;
	z-index: 0;

	@media screen and (min-width: $breakpoint-md) {
		position: relative;
		top: 0;
		height: auto;
		transform: translateX(-12px);
		z-index: 2;
	}

	@media screen and (min-width: 1200px) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		z-index: 0;
	}
}

.hiddenBoxVisibleNonMember {
	position: relative;
	top: 0;
	transform: translateY(-18px);

	@media screen and (min-width: $breakpoint-md) {
		transform: translateY(0) translateX(-20px);
	}
}

.hiddenBoxVisibleWithBlur {
	z-index: 20;

	@media screen and (min-width: $breakpoint-md) {
		z-index: 0;
	}
}

.closeHeader {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.closeIcon {
	width: 38px;
	height: 38px;
	background-size: 38px;
	background-image: url('../../icons/close_info.png');
	cursor: pointer;
}

// Pre-load hover image
body::after {
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
	z-index: -1;
	content: url('../../icons/close_info_hover.png');
}

.closeIcon:hover {
	background-image: url('../../icons/close_info_hover.png');
}

.error {
	margin-top: 1rem;
	color: red;
}

.communityInfoWidth {
	min-width: 500px;
}

.weirdResolution {
	transform: translateX(-100%);
}

.claimContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	@media screen and (min-width: $breakpoint-md) {
		flex-direction: row;
	}
}

.inputLabel {
	margin-bottom: 0.75rem;
}

.walletInputContainer {
	display: flex;
	flex-direction: row;
	flex: 1;
}

.walletLedger {
	padding: 0.675rem;
	width: 56px;
	height: 56px;
	background-color: var(--color-button-basic);
	border-top-left-radius: 9px;
	border-bottom-left-radius: 9px;
}

.walletInput {
	flex: 1;
	padding: 0 0.75rem;
	// TODO: Find a solution why this ruins the placeholder padding
	// font-family: var(--font-primary);
	font-style: normal;
	font-weight: 400;
	font-size: clamp(0.6rem, calc(5vw - 0.5rem), 0.75rem);
	line-height: 1.5;
	border: 1px solid var(--color-button-basic);
	border-top-right-radius: 9px;
	border-bottom-right-radius: 9px;
}

.walletInputError {
	border-color: red;
}

.discordMessage {
	margin-top: 1rem;
	border-left: 2px solid var(--color-bg-purple);
	padding-left: 0.5rem;
	font-size: clamp(0.525rem, calc(5vw - 0.5rem), 0.625rem);

	@media screen and (min-width: $breakpoint-md) {
		font-size: clamp(0.875rem, calc(5vw - 0.5rem), 1rem);
	}
}

.discordLink {
	text-decoration: underline;
	color: var(--color-black);
	font-weight: 800;
	cursor: pointer;
}

.transactionInProgress {
	width: 100%;
	text-align: center;
	font-weight: 800;
	animation: blinker 1.5s linear infinite;
	color: #f6c349;
}

@keyframes blinker {
	50% {
		opacity: 0.5;
	}
}

.pleaseWait {
	width: 100%;
	text-align: center;
	font-size: 1rem;
}
